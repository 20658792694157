import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Section, SectionTitle } from "../../components/section";
import ArrowIcon from "../../components/Icons/ArrowIcon";
import FinanceIcon from "../../components/Icons/FinanceIcon";
import RtoIcon from "../../components/Icons/RtoIcon";
import ListArrow from "../../images/list-arrow.svg";
import BreakpointUp from "../../components/Media/BreakpointUp";
import DefaultButton from "../../components/button/DefaultButton";

const SectionGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
`;

const SectionItemGrid = styled.div`
  &.odd{
    .card {
      ${BreakpointUp.lg`
				flex-direction: row-reverse;
			`}
      .card-icon {
        ${BreakpointUp.lg`						
					left:-78px;
					right:auto;
				`}
      }
    }
  }
`;

const CardFinance = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CardFigure = styled.figure`
  position: relative;
  width: 100%;
  min-height: 1px;
  margin:0 0 30px;
  padding:0;
  ${BreakpointUp.lg`		
		flex: 0 0 50%;
		max-width: 50%;
    padding:0 60px;
    margin:0;
  `}
  a {
    display: block;
    position: relative;
  }
`;
const CardIcon = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  width: 62px;
  height: 62px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -52px;
  left: 50%;
  transform: rotate(45deg) translate(-50%, 0);
  ${BreakpointUp.lg`		
		width: 92px;
		height: 92px;	
	`}
  svg {
    transform: rotate(-45deg);
    width: 40px;
    ${BreakpointUp.lg`		
			width: 60px;
		`}
  }
  ${BreakpointUp.lg`		
		top: 50%;
		right: -15px;
		left: auto;
		bottom:auto;
		transform: rotate(45deg) translate(0, -50%);
	`}
`;

const CardBody = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  margin-bottom: 30px;
  padding:0;
  ${BreakpointUp.lg`		
		flex: 0 0 50%;
		max-width: 50%;
    padding:0 60px;
  `}
`;


const FinancePoint = styled.ul`
  margin: 0 -20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${BreakpointUp.lg`
		flex-direction: inherit;
	`}
`;

const FinancePointItem = styled.li`
  color: #231f20;
  font-weight: 700;
  margin-bottom: 15px;
  position: relative;
  padding-left: 40px;
  padding-right: 20px;
  ${BreakpointUp.lg`		
		flex: 0 0 50%;
		max-width: 50%;
	`}
  &:before {
    content: "";
    display: inline-block;
    width: 12px;
    position: absolute;
    left: 20px;
    top: 6px;
    height: 12px;
    background-image: ${(props) => props.bgImg};
    background-repeat: no-repeat;
  }
`;
const ButtonToolbar = styled.div`
  display: block;
  margin: 30px 0;
  & .btn {
    display: inline-flex;
    flex-direction: row-reverse;
    padding: 11px 24px;
    & svg {
      transition: transform 0.5s ease-out;
    }
    & .text {
      margin-left: 0;
      margin-right: 10px;
    }
    &:hover {
      & svg {
        transform: translateX(6px);
      }
    }
  }
`;

const FinanceSection = ({ sectionData }) => {
  return (
    <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
      <div className="container">
        <SectionTitle mb="60px" xmb="30px">
          {sectionData[0].sectionTitle}
        </SectionTitle>
        <SectionGrid>
          <SectionItemGrid className="even">
            <CardFinance className="card">
              <CardFigure className="card-img">  
                <Link to="/rto">
                  <Img fluid={sectionData[0].sectionImages[0].fluid} />
                  <CardIcon className="card-icon">
                    <FinanceIcon />
                  </CardIcon>
                </Link>
              </CardFigure>
              <CardBody className="card-body">
                <h3>{sectionData[0].subTitle}</h3>
                <p>{sectionData[0].sectionDescription.sectionDescription}</p>
                <FinancePoint>
                  {sectionData[0].features.map((item, i) => {
                    return (
                      <FinancePointItem key={i} bgImg={`url(${ListArrow})`}>
                        {item.content}
                      </FinancePointItem>
                    );
                  })}
                </FinancePoint>
                <ButtonToolbar>
                  <Link to="/financing">
                    <DefaultButton text="Apply Now" icon={<ArrowIcon />} />
                  </Link>
                </ButtonToolbar>
              </CardBody>
            </CardFinance>
          </SectionItemGrid>
          <SectionItemGrid className="odd">
            <CardFinance className="card">
              <CardFigure className="card-img">
                <Link to="/rto">
                  <Img fluid={sectionData[1].sectionImages[0].fluid} />
                  <CardIcon className="card-icon">
                    <RtoIcon />
                  </CardIcon>     
                </Link>           
              </CardFigure>
              <CardBody className="card-body">
                <h3>{sectionData[1].subTitle}</h3>
                <p>{sectionData[1].sectionDescription.sectionDescription}</p>
                <FinancePoint>
                  {sectionData[1].features.map((item, i) => {
                    return (
                      <FinancePointItem key={i} bgImg={`url(${ListArrow})`}>
                        {item.content}
                      </FinancePointItem>
                    );
                  })}
                </FinancePoint>
                <ButtonToolbar>
                  <Link to="/rto">
                    <DefaultButton text="Apply Now" icon={<ArrowIcon />} />
                  </Link>
                </ButtonToolbar>
              </CardBody>
            </CardFinance>
          </SectionItemGrid>
        </SectionGrid>
      </div>
    </Section>
  );
};

export default FinanceSection;
