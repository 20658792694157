import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from "uuid"
import { Section, SectionTitle, SectionDescription } from "../../components/section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import DefaultButton from "../../components/button/DefaultButton"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import QuotePopup from '../../components/QuotePopup'
import Image from '../../components/image'

import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../../components/Content';

const ContentGrid = styled.div`
`
const ContentTopGrid = styled.div`
	background-color:#fff;
	position:relative;		
	padding:60px 0;
	${BreakpointUp.lg`	
		padding:90px 0;
	`}
`
const ContentBottomGrid = styled.div`
	background-color:#F4F8FC;
	position:relative;
	padding:60px 0;	
	${BreakpointUp.lg`
		padding:90px 0;
    `}
`
const ProductReview = styled.ul`
	padding:0;
	margin-bottom:30px;
	width:100%;
	list-style:none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;	
	margin-right: -5px;
    margin-left: -5px;
	${BreakpointUp.sm`	
		margin-right: -15px;
		margin-left: -15px;
	`}
    
`
const ProductReviewItem = styled.li`
	margin:0;
	padding:5px;
	text-align:center;
	flex-basis: 0;
    flex-grow: 1;
	max-width: 100%;
	${BreakpointUp.sm`	
		margin-top:15px;
		padding:5px 15px;
	`}
	+ li{
		border-left:1px solid #E5E5E5;
	}
	& .number{
		display:block;
		font-family: 'Fira Sans', serif;
		color:#002337;
		margin-bottom: 2px;
		font-weight: 700;
		font-size:28px;
		line-height: 40px;
		${BreakpointUp.sm`	
			font-size:32px;
			line-height: 44px;
		`}
		${BreakpointUp.lg`	
			font-size:42px;
			line-height: 54px;
		`}
	}
	& .text{
		color:#959EA5;
		font-weight:500;
		display: block;
		text-align:center;
		max-width: 118px;
		width: 100%;
		margin: 0 auto;
		font-size: 14px;
		line-height: 20px;
		${BreakpointUp.sm`	
			font-size: 16px;
			line-height: 22px;
		`}
		${BreakpointUp.lg`	
			font-size:18px;
			line-height: 26px;
			max-width: none;
		`}
	}
`
const ButtonToolbar = styled.div`	
	display: block;
    text-align: center;
	> a{
		display:inline-block;	
	}
	& .btn{
		& svg{
			transition: transform 0.5s ease-out;
		}
		&:hover{
			& svg{
				transform: translateX(6px);
			}
		}
	}
`
const ReadMoreButton = styled.div`	
display: inline-block;
`
const ServiceGrid = styled.div`
	display: flex;    
    flex-wrap: wrap;
	margin-right:- 15px;
	margin-left: -15px;
	align-items:center;
	${BreakpointUp.lg`
		margin-right:-30px;
		margin-left: -30px;
	`}
	${BreakpointUp.xl`
		margin-right:-50px;
		margin-left: -50px;
	`}
`
const ServiceGridLeft = styled.div`
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	${BreakpointUp.lg`
		padding-right: -30px;
		padding-left: -30px;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	`}
	${BreakpointUp.xl`
		padding-right: 50px;
		padding-left: 50px;
	`}
	${BreakpointDown.lg`
		display:none;
	`}

`
const ServiceGridRight = styled.div`
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	${BreakpointUp.lg`
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
		padding-right: 30px;
		padding-left: 30px;
	`}
	${BreakpointUp.xl`
		padding-right: 50px;
		padding-left: 50px;
	`}

`
const ProductService = styled.div`
	width: 100%;
	display: flex;    
    flex-wrap: wrap;
    margin-right: -15px;
	margin-left: -15px;
	margin-bottom:15px;
`
const ProductServiceItem = styled.div`
	padding-right: 15px;
	padding-left: 100px;
	position:relative;
	margin-bottom:15px;
	${BreakpointUp.sm`
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom:30px;
	`}
	${BreakpointUp.xl`
		padding-left: 130px;
	`}
	& .icon{		
		width:60px;
		height:60px;
		background-color:#fff;	
		border-radius:50%;
		box-shadow:0 0 8px rgba(0,0,0,0.05);
		position: absolute;
		top:0;
		left:15px;
		display: flex;
		align-items: center;
		justify-content: center;
		${BreakpointUp.xl`
			width:90px;
			height:90px;
		`}
		
		svg{
			fill:#666;
		}
	}
	& .text{
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 60px;
		${BreakpointUp.xl`
			min-height: 90px;
		`}
		& h3{
			font-weight:500;
			margin:0;
			font-size:16px;
			line-height:26px;
			${BreakpointUp.sm`
				font-size:18px;
				line-height:28px;
			`}
			${BreakpointUp.xl`
				font-size:20px;
				line-height:30px;
			`}
		}
	}
`
class IntroSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisibleQuote: false,
		}
		this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
	}
	showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('modal-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('modal-open')
    }
  }
	render() {
		const { sectionData } = this.props
		const { isVisibleQuote } = this.state
		const PostContent = HTMLContent || Content;
		return(
			<>
			<Section pt="0" pb="0" xpt="0" xpb="0" >			
					<ContentGrid>	 	
						<ContentTopGrid>
							<div className="container">
								<SectionTitle mb="15px" maxWidth="890px">{sectionData[0].sectionTitle}</SectionTitle>
								<SectionDescription maxWidth="1120px">
									<PostContent content={generateHTML(sectionData[0].sectionDescription.childMarkdownRemark.rawMarkdownBody)} />
								</SectionDescription>
								<ProductReview>
									{
										sectionData[0].features.map((item, i)=> {
											return(
												<ProductReviewItem key={v4()}>
													<span className="number">{item.feature}</span>
													<span className="text">{item.title}</span>
												</ProductReviewItem>
											)
										})
									}
								</ProductReview>
								<ButtonToolbar className="btn-reverse">
									<Link to={sectionData[0].sectionButtonLink}><DefaultButton text="Read More"  icon={<ArrowIcon />}/></Link>
								</ButtonToolbar>
							</div>
						</ContentTopGrid>					
						<ContentBottomGrid>
							<div className="container">
									<ServiceGrid>
										<ServiceGridLeft>
											<Image name="cartoon-charactor.png" alt="charactor" />
										</ServiceGridLeft>
										<ServiceGridRight>
											<SectionTitle mb="20px" textAlign="left">{sectionData[1].sectionTitle}</SectionTitle>
											<ProductService>
												{
													sectionData[1].sectionImages.map((item, i)=>{
														return(
															<ProductServiceItem key={v4()}>
																<div className="icon">
																	<img src={item.file.url} alt={item.title} />
																</div>
																<div className="text">
																	<h3>{item.title}</h3>									
																</div>
															</ProductServiceItem>
														)
													})
												}
											</ProductService>
											<ButtonToolbar className="btn-reverse text-left">
												<ReadMoreButton onClick={this.showQuote}><DefaultButton text="Request A Quote Now"  icon={<ArrowIcon />}/></ReadMoreButton>
											</ButtonToolbar>
										</ServiceGridRight>
									</ServiceGrid>
							</div>
						</ContentBottomGrid>
					</ContentGrid>
				
			</Section>
			<QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
			</>
		)
	}
}

export default IntroSection