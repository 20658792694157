import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { v4 } from "uuid";
import ProductCard from "../../components/ProductCard";
import ArrowIcon from "../../components/Icons/ArrowIcon";
import DefaultButton from "../../components/button/DefaultButton";
import { ExploreMore } from "../../components/section";
import BreakpointUp from "../../components/Media/BreakpointUp";
import BreakpointDown from "../../components/Media/BreakpointDown";

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;
const LeftSidebar = styled.div`
  position: relative;
  padding: 0 15px;
  width: 100%;
  .sticky-wrapper {
    position: fixed;
    top: 130px;
    width: 100%;
  }
  .no-sticky {
    position: relative;
    top: 0;
  }
  ${BreakpointUp.lg`		
        flex:0 0 16.666667%;
	`}
  @media(max-width: 1023px) {
    .sticky-wrapper {
      position: relative !important;
      top: 0;
    }
  }
`;
const RightContent = styled.div`
  padding: 0 15px;
  width: 100%;
  ${BreakpointUp.lg`		
        flex:0 0 83.333333%;
	`}
`;

const TabNav = styled.div`
  width: 100%;
  margin-bottom: 30px;
  ${BreakpointDown.lg`	
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin:0 auto 30px;
    text-align:center;
  `}
  ${BreakpointUp.lg`	
    max-width: 100%;
    width: 100%;
    position: sticky;
    top: 90px;    
  `}
  ${BreakpointUp.xl`
    top: 160px;    
  `}
`;

const TabItem = styled.div`
  position: relative;
  display: block;
  padding: 15px 0;
  ${BreakpointDown.lg`
        display:inline-block;
        padding:0 15px;
        
    `}
  &.active {
    font-weight: 700;
    div {
      &:before {
        background-color: #231f20;
      }
    }
  }
`;
const TabLink = styled.div`
  color: #231f20;
  display: block;
  position: relative;
  line-height: 20px;
  padding: 10px 0;
  ${BreakpointUp.lg`
    padding: 0 0 0 20px;
    `}
  &:before {
    content: "";
    left: 0;
    background-color: transparent;
    display: inline-block;
    position: absolute;
    height: 4px;
    width: 100%;
    top: auto;
    bottom: 0;
    ${BreakpointUp.lg`
            height: 100%;
            width: 4px;
            top: 0;
            bottom:auto;
		`}
  }
  &:hover {
    color: #f99207;
    cursor: pointer;
  }
`;

const TabContent = styled.div`
  margin: 0;
`;
const TabPane = styled.div`
  ${BreakpointUp.md`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 0 30px;
  `}
`;

const RoofProducts = styled.div`
  max-width: 1124px;
  width: 100%;
  margin: auto;
`;

class ProductCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {     
      previousPos: 0,
      products: this.props.data,
      currentTab: "all",
      limit: 10,
    };
    this.showAllProducts = this.showAllProducts.bind(this);
    this.showRoof = this.showRoof.bind(this);
    this.categoryProducts = this.categoryProducts.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }
  changeLimit() {
    this.setState({
      limit: this.state.limit + 10
    })
  }
  showAllProducts() {
    this.setState({
      products: this.props.data,
      currentTab: "all",
      limit: 10,
    });
  }
  showRoof(roof) {
    let currentProducts = [];
    const { data } = this.props;
    data.forEach(function (item) {
      if (item.node.productRoof.roofName === roof) {
        currentProducts.push(item);
      }
    });
    this.setState({
      products: currentProducts,
      currentTab: roof,
      limit: 10,
    });
  }
  categoryProducts(roof) {
    let currentProducts = [];
    const { data } = this.props;
    data.forEach(function (item) {
      if (item.node.productRoof.roofName === roof) {
        currentProducts.push(item);
      }
    });
    return currentProducts.length
  }

  render() {
    const { buttonTxt, buttonLink } = this.props;
    const { products, currentTab, limit } = this.state;
    return (
      <>
        {this.props.roof ? (
          <RoofProducts>
            <TabContent>
              <TabPane>
                {products.map((item, i) => {
                  if (i < limit) {
                    return (
                      <ProductCard
                        key={v4()}
                        data={item.node}
                        showQuote={this.props.showQuote}
                      />
                    );
                  }
                })}
              </TabPane>
              {products.length > limit && (
                <ExploreMore justifyContent="center" mt="30px" onClick={()=>this.changeLimit()}>
                  <button type="button" aria-label="load more">
                    <DefaultButton text="Load More..."
                    ></DefaultButton>
                  </button>
                </ExploreMore>
              )}
            </TabContent>
          </RoofProducts>
        ) : (
          <SectionGrid>
            <LeftSidebar>
              <TabNav>
                <TabItem className={currentTab === "all" ? "active" : ""}>
                  <TabLink onClick={this.showAllProducts}>All Products</TabLink>
                </TabItem>
                {this.categoryProducts("Vertical Roof") > 0 && (
                  <TabItem
                    className={currentTab === "Vertical Roof" ? "active" : ""}
                  >
                    <TabLink onClick={() => this.showRoof("Vertical Roof")}>
                      Vertical Roof
                    </TabLink>
                  </TabItem>
                )}
                
                {this.categoryProducts("A-Frame Roof") > 0 && (
                  <TabItem
                    className={currentTab === "A-Frame Roof" ? "active" : ""}
                  >
                    <TabLink onClick={() => this.showRoof("A-Frame Roof")}>
                      A-Frame Roof
                    </TabLink>
                  </TabItem>
                )}
                {this.categoryProducts("Regular Roof") > 0 && (
                  <TabItem
                    className={currentTab === "Regular Roof" ? "active" : ""}
                  >
                    <TabLink onClick={() => this.showRoof("Regular Roof")}>
                      Regular Roof
                    </TabLink>
                  </TabItem>
                )}
                
              </TabNav>
            </LeftSidebar>
            <RightContent>
              <TabContent>
                <TabPane>
                  {products.map((item, i) => {
                    if (i < limit) {
                      return (
                        <ProductCard
                          key={v4()}
                          data={item.node}
                          showQuote={this.props.showQuote}
                        />
                      );
                    }
                  })}
                </TabPane>
                {products.length > limit && (
                  <ExploreMore justifyContent="center" mt="30px" onClick={()=>this.changeLimit()}>
                    <button type="button" aria-label="load more">
                      <DefaultButton text="Load More..."
                      ></DefaultButton>
                    </button>
                  </ExploreMore>
                )}
              </TabContent>
            </RightContent>
          </SectionGrid>
        )}
      </>
    );
  }
}

export default ProductCardList;
