import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import ArrowIcon from "../../components/Icons/ArrowIcon";
import BreakpointUp from "../../components/Media/BreakpointUp";

const Card = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fff;
`;
const CardTitle = styled.h3`
  margin: 0 0 10px;
  color: #002337;
  font-size: 24px;
  line-height: 34px;
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`;
const CardFigure = styled(Link)`
  position: relative;
  width: 100%;
  height: 270px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
  & .gatsby-image-wrapper {
    height: 100%;
  }
`;
const CardContent = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 30px 15px;
  ${BreakpointUp.lg`		
		padding:30px;
	`}
  > a {
    color: #002337;
    font-weight: 700;
    .text {
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
      + .icon {
        margin-left: 5px;
      }
    }
    .icon {
      display: inline-block;
      vertical-align: text-top;
      height: 20px;
      & svg {
        fill: #002337;
        transition: transform 0.3s ease-out;
      }
    }
    &:hover {
      color: #002337;
      &:after {
        transform-origin: left center;
        transform: translateZ(0) scaleX(1);
      }
      svg {
        fill: #002337;
        transform: translateX(4px);
      }
    }
  }
`;
const CardDescription = styled.p``;

const CategoryCard = ({ data }) => {
  return (
    <Card className="card">
      <CardFigure to={data.link} className="card-img">
        <Img fluid={data.categoryImage.fluid} />        
      </CardFigure>
      <CardContent className="card-body">
        <CardTitle>{data.name}</CardTitle>
        {data.description && (
          <CardDescription>{data.description.description}</CardDescription>
        )}
        <Link to={data.link}>
          <span className="text">Explore</span>
          <span className="icon">
            <ArrowIcon />
          </span>
        </Link>
      </CardContent>
    </Card>
  );
};

export default CategoryCard;
