import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { v4 } from "uuid";
import {
  Section,
  SectionTitle,
  SectionDescription,
} from "../../components/section";
import ArticleCard from "../../components/ArticleList";
import DefaultButton from "../../components/button/DefaultButton";
import ArrowIcon from "../../components/Icons/ArrowIcon";
import BreakpointUp from "../../components/Media/BreakpointUp";

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  ${BreakpointUp.lg`				
      padding:0 30px;
      grid-template-columns: repeat(3, 1fr);
	`}
`;
const ButtonToolbar = styled.div`
  display: block;
  text-align: center;
  margin-top: 30px;
  > a {
    display: inline-block;
  }
  & .btn {
    & svg {
      transition: transform 0.5s ease-out;
    }
    &:hover {
      & svg {
        transform: translateX(6px);
      }
    }
  }
`;
const ArticleSection = ({ sectionData, articles }) => {
  return (
    <Section
      pt="90px"
      pb="90px"
      xpt="60px"
      xpb="60px"
      bg="linear-gradient(to bottom,#ffffff 0%,#ffffff 50%, #F4F8FC 100%)"
    >
      <div className="container">
        <SectionTitle mb="10px" maxWidth="850px">
          {sectionData.sectionTitle} {sectionData.subTitle}
        </SectionTitle>
        {sectionData.sectionDescription && (
          <SectionDescription mb="30px">
            {sectionData.sectionDescription.sectionDescription}
          </SectionDescription>
        )}

        <ArticleGrid>
          {articles.edges.map((item, i) => {
            if (i < 3) {
              return <ArticleCard key={v4()} data={item.node} />;
            }
            return true;
          })}
        </ArticleGrid>
        <ButtonToolbar className="btn-reverse">
          <Link to={sectionData.sectionButtonLink}>
            <DefaultButton
              text={sectionData.sectionButtonText}
              icon={<ArrowIcon />}
            />
          </Link>
        </ButtonToolbar>
      </div>
    </Section>
  );
};
export default ArticleSection;
