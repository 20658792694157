import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LazyLaod from "react-lazyload";
import { graphql } from "gatsby";

// import HeroMobile from "../sections/Home/HeroMobile";
import HeroSection from "../sections/Home/HeroSection";
import ProductSection from "../sections/Home/ProductSection";
import CategorySection from "../sections/Home/CategorySection";
import IntroSection from "../sections/Home/IntroSection";
import CallToAction from "../components/CallToAction";
import FinanceSection from "../sections/Home/FinanceSection";
import ArticleSection from "../sections/Home/ArticleSection";

const IndexPage = (pageData) => {
  const heroData = pageData.data.contentfulHome.heroSection;
  const categorySectionData = pageData.data.contentfulHome.categorySection;
  const categories = pageData.data.allContentfulProductCategory;
  const introData = pageData.data.contentfulHome.introSection;
  const productSectionData = pageData.data.contentfulHome.productSection;
  const products = pageData.data.allContentfulProduct;
  const ctaSectionData = pageData.data.contentfulHome.ctaSection;
  const financeData = pageData.data.contentfulHome.financeSection;
  const articleSectionData = pageData.data.contentfulHome.articleSection;
  const articles = pageData.data.allContentfulArticle;
  let featuredProductsData = [];
  products.edges.forEach(function (item) {
    if (item.node.featuredProduct) {
      featuredProductsData.push(item);
    }
  });
  return (
    <Layout>
      <SEO
        title={pageData.data.contentfulHome.metaTitle}
        description={
          pageData.data.contentfulHome.metaDescription.metaDescription
        }
      />

      {/* <HeroMobile sectionData={heroData} /> */}
      <HeroSection sectionData={heroData} />
      <ProductSection sectionData={productSectionData} products={products} />
      <CategorySection
        sectionData={categorySectionData}
        categories={categories}
      />
      <LazyLaod>
        <IntroSection sectionData={introData} />
      </LazyLaod>
      <CallToAction sectionData={ctaSectionData} />
      <FinanceSection sectionData={financeData} />
      <ArticleSection sectionData={articleSectionData} articles={articles} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    contentfulHome {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroSection {
        sectionTitle
        subTitle
        sectionButtonLink
        sectionButtonText
        sectionImages {
          title
          fluid(maxHeight: 600) {
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
            base64
          }
        }
        mobileImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      categorySection {
        sectionTitle
        sectionDescription {
          sectionDescription
        }
      }
      introSection {
        sectionTitle
        subTitle
        sectionDescription {
          id
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        features {
          feature
          title
        }
        sectionImages {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          title
          file {
            url
          }
        }
        sectionButtonText
        sectionButtonLink
      }
      productSection {
        sectionTitle
        subTitle
        sectionDescription {
          sectionDescription
        }
        sectionButtonText
        sectionButtonLink
      }
      ctaSection {
        sectionTitle
        subTitle
        sectionButtonText
        sectionButtonLink
      }
      financeSection {
        sectionTitle
        subTitle
        sectionDescription {
          sectionDescription
        }
        sectionImages {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        features {
          content
        }
        sectionButtonText
        sectionButtonLink
      }
      articleSection {
        sectionTitle
        sectionDescription {
          sectionDescription
        }
        sectionButtonText
        sectionButtonLink
        subTitle
      }
    }
    allContentfulProductCategory {
      edges {
        node {
          name
          description {
            description
          }
          link
          categoryImage {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: createdAt }) {
      edges {
        node {
          thumbnail {
            title
            fluid {
              base64
              src
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          createdAt(fromNow: true)
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          url
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          productName
          url
          popularLabel
          featuredProduct
          image {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
        }
      }
    }
  }
`;
