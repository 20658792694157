import React from "react"
import styled from 'styled-components'
// import Img from 'gatsby-image'
import { Link } from "gatsby"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import DefaultButton from "../../components/button/DefaultButton"
import BreakpointDown from "../../components/Media/BreakpointDown"
import BreakpointUp from "../../components/Media/BreakpointUp"
import Image from "../../components/image"
import ProductVideo from '../../images/productVideo.mp4'


const SectionHero = styled.section`	
	display:block;
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};	
	position:relative;
	${BreakpointDown.xl`
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};
	`}
	& .container{
		max-width:inherit;
	}
`
const ImageCaption = styled.div`
	position: absolute;	
	z-index: 10;
	color: white;
	bottom: 0px;
	left: 0;
	right: 0;
	text-align: left;
	display: flex;
	flex-wrap:wrap;
	align-items: center;
	justify-content: space-around;
	margin: 0 auto;
	padding:30px;
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 70%,rgba(0,0,0,1) 100%);
	${BreakpointUp.lg`
		background: linear-gradient(to bottom,rgba(255,255,255,0.01),#030504);
	`}
	${BreakpointUp.xl`
		padding:140px 30px 30px;
	`}
`

const ImageTitle = styled.h1`	
	display:block;
	font-family: 'Fira Sans', serif;
	color:#fff;
	font-weight: 700;
	margin: 0 0 15px;
	font-size: 20px;
	line-height: 28px;
	${BreakpointUp.md`
		font-size: 28px;
		line-height: 38px;
		font-weight: 900;
		margin-bottom: 5px;
	`}
	@media (min-width: 1366px) {
		font-size: 42px;
		line-height: 52px;
	}
`

const ImageSubTitle = styled.p`	
	color:#fff;
	font-size: 16px;
	line-height: 26px;
	${BreakpointUp.md`
		font-size: 18px;
		line-height: 28px;	
	`}
	@media (min-width: 1366px) {
		font-size: 30px;
		line-height: 40px;
	}
	
`
const CaptionHeading = styled.div`
	position: relative;
    width: 100%;
	padding:0 10px;
	text-align:center;
	${BreakpointUp.xl`
		text-align:left;
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	`}
`
const ButtonToolbar = styled.div`	
	position: relative;
    width: 100%;
	text-align:center;
	${BreakpointUp.xl`
		text-align:left;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	`}
	& a{
		display:inline-flex
	}
	& .btn{
		display: flex;
		flex-direction: row-reverse;
		${BreakpointDown.sm`
			font-size: 14px;
			font-weight: 400;
		`}
		& svg{
			transition: transform 0.5s ease-out;
		}
		& .text{
			margin-left:0;
			margin-right:10px;
		}
		&:hover{
			& svg{
				transform: translateX(6px);
			}
		}
	}

`
// const ImageSlider = styled.div`	
// 	> .gatsby-image-wrapper {
// 		height: calc(100vh - 100px);
// 	}
// `
const ImageSlider = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
	height:calc(100vh - 130px);
	${BreakpointUp.md`
		height:calc(100vh - 60px);
	`}
	${BreakpointUp.xl`
    	height:calc(100vh - 110px);
	`}
    &::before {
		display: block;
		content: "";
		padding-top: 56.25%;
    }
	> .hero-img .gatsby-image-wrapper{
		position: absolute !important;
	}
    > video, > .hero-img .gatsby-image-wrapper, > iframe{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        object-fit: cover;
    }
`
const ImgCharactor = styled.div`
	position: absolute;
	bottom: 0;
	right: 20%;
	width: 153px;
	display:none;
	${BreakpointUp.md`
		display:block;
	`}
	@media (min-width: 1366px){
		width: 233px;	
	}
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const HeroSection = ({ sectionData }) => {
	return(
		<SectionHero pt="110px" xpt="60px" pb="0" className="hero">
			<div className="container">
				<ImageSlider className="hero-video-image">
					{/* <Img fluid={sectionData.sectionImages[0].fluid} alt={sectionData.sectionImages[0].title} />	 */}
					<div className="hero-img">
						<Image name="hero-banner-mobile.jpg" />
					</div>
					<video playsinline muted loop autoplay="autoplay" className="hero-video" >
						<source src={ProductVideo} type="video/mp4" />
					</video>

					<ImgCharactor><Image name="charactor.png" alt="charactor"/></ImgCharactor>
					<ImageCaption>
						<CaptionHeading>
							<ImageTitle>{sectionData.sectionTitle}</ImageTitle>
							<ImageSubTitle>{sectionData.subTitle}</ImageSubTitle>
						</CaptionHeading>
						<ButtonToolbar>
							<Link to={sectionData.sectionButtonLink}><DefaultButton text={sectionData.sectionButtonText}  icon={<ArrowIcon />}/></Link>
						</ButtonToolbar>
					</ImageCaption>
				</ImageSlider>
			</div>
		</SectionHero>
	)
}

export default HeroSection
