import React from "react";
import styled from "styled-components";
import DefaultOutlineButton from "../button/DefaultOutlineButton";
import { Section } from "../section";
import BreakpointUp from "../Media/BreakpointUp";
import PhoneIcon from "../Icons/PhoneIcon";
import BuildingIcon from "../Icons/BuildingIcon";
import LinePattern from "../../images/line-pattern.png";

const SectionContent = styled.div`
  & strong{
    color: #fff;
    display:block;  
    font-family: 'Fira Sans', serif;
    font-weight: 700;
    text-align: center;
    margin:0 auto;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 768px) {
      font-size: 34px;
      line-height: 44px;
    }
    @media (min-width: 992px) {
      font-size: 42px;
      line-height: 52px;
    }
  }
  & p {
    font-family: "Fira Sans", serif;
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 768px) {
      font-size: 34px;
      line-height: 44px;
    }
    @media (min-width: 992px) {
      font-size: 42px;
      line-height: 52px;
    }
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -15px;
  flex-direction: column;
  ${BreakpointUp.sm`
		flex-direction: inherit;
	`}
  a {
    padding: 0 15px;
    width: 100%;
    ${BreakpointUp.sm`
			display:inline-flex;
			padding:0 15px;
			width:auto;
    `}
    .btn{
      display: block;
      ${BreakpointUp.sm`
        display: inline-flex;
      `}
    }
    + a {
      margin-top: 15px;
      ${BreakpointUp.sm`
				margin-top:0;
			`}
    }
  }
`;

const CallToAction = ({ sectionData }) => {
  return (
    <Section
      bg="#002337"
      pt="110px"
      pb="110px"
      xpt="60px"
      xpb="60px"
      bgBefore={`url(${LinePattern})`}
      opacityBefore="0.10"
      bgBeforeWidth="100%"
      bgBeforeHeight="100%"
      bgBeforeRepeat="repeat"
      topBefore="0px"
    >
      <div className="container">
        <SectionContent>
          <strong>
            {sectionData.sectionTitle}
          </strong>
          <p>{sectionData.subTitle}</p>
          <ButtonWrap>
            <a
              href="https://mrcarports.sensei3d.com"
              target="_blank"
              rel="noreferrer"
            >
              <DefaultOutlineButton
                icon={<BuildingIcon />}
                text="Design your Building"
              />
            </a>
            <a href="tel:8773505464">
              <DefaultOutlineButton
                icon={<PhoneIcon />}
                text="(877) 350-5464"
              />
            </a>
          </ButtonWrap>
        </SectionContent>
      </div>
    </Section>
  );
};

export default CallToAction;
