import React from "react"
import { Section, SectionTitle, SectionDescription } from "../../components/section"
import ProductCardList from "../../components/TabProductCardList"
import QuotePopup from '../../components/QuotePopup'

class ProductSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisibleQuote: false,
		}
		this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
	}
	showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('modal-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('modal-open')
    }
  }
	render() {
		const { isVisibleQuote } = this.state
		const { sectionData, products } = this.props
		let productsData = []
		productsData=products.edges
		return(
			<>
				<Section pt="70px" pb="70px" xpt="60px" xpb="60px">
					<div className="container">
						<SectionTitle>{sectionData.sectionTitle} {sectionData.subTitle}</SectionTitle>
						<SectionDescription><p>{sectionData.sectionDescription.sectionDescription}</p></SectionDescription>				
						<ProductCardList
							data={productsData}
							limit="15"
							buttonTxt={sectionData.sectionButtonText} 
							buttonLink={sectionData.sectionButtonLink}
							showQuote = {this.showQuote}
						/>
					</div>
				</Section>				
				<QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
			</>
		)
	}
}

export default ProductSection