import React from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import {
  Section,
  SectionTitle,
  SectionDescription,
} from "../../components/section";
import CategoryCard from "../../components/CategoryCard";
import BreakpointUp from "../../components/Media/BreakpointUp";

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  .card {
    margin-bottom: 0;
  }
`;

const SectionTopHeading = styled.div``;
const Masonry = styled.div`
  position: relative;
  column-count: 1;
  column-gap: 30px;
  ${BreakpointUp.sm`
		column-count: 2;
	`}
  ${BreakpointUp.lg`
		column-count: 3;
	`}
`;
const MasonryItem = styled.div`
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  padding-bottom: 30px;
`;
const CategorySection = ({ sectionData, categories }) => {
  return (
    <Section pt="70px" pb="70px" mpb="30px" bg="#F4F8FC">
      <div className="container">
        <SectionGrid>
          <SectionTopHeading>
            <SectionTitle textAlign="left" ml="0px">
              {sectionData.sectionTitle}
            </SectionTitle>
            <SectionDescription textAlign="left" maxWidth="750px" ml="0px">
              {sectionData.sectionDescription.sectionDescription}
            </SectionDescription>
          </SectionTopHeading>
          <Masonry className="masonry">
            {categories.edges.map((item ) => {
              return (
                <MasonryItem className="masonry-item">
                  <CategoryCard key={v4()} data={item.node} />
                </MasonryItem>
              );
         
              })}
            </Masonry>

          {/* <CategoryRight>
            <Masonry className="masonry">
              {categories.edges.map((item, i) => {
                if (i > 6) {
                  return (
                    <MasonryItem className="masonry-item">
                      <CategoryCard key={v4()} data={item.node} />
                    </MasonryItem>
                  );
                }
                return true;
              })}
            </Masonry>
          </CategoryRight> */}
        </SectionGrid>
      </div>
    </Section>
  );
};

export default CategorySection;
